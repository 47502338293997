import Link from 'next/link';

import { Address } from 'abitype';

import { AddressDisplay } from '@layr-labs/eigen-kit/react';

interface OperatorLinkProps {
  address?: string;
  name?: string;
  logo?: string;
}

export function OperatorLink({ address, name, logo }: OperatorLinkProps) {
  return (
    <Link
      href={`/operator/${address}`}
      className="flex items-center gap-1.5 after:content-['_↗'] hover:underline"
    >
      <img
        src={logo || '/tokenIcon/beaconChainETH.svg'}
        onError={(e) => {
          e.currentTarget.src = '/tokenIcon/beaconChainETH.svg';
        }}
        height={20}
        width={20}
        alt="operator logo"
        className="h-5 w-5 rounded object-contain"
      />
      {name || <AddressDisplay address={address as Address} />}
    </Link>
  );
}
