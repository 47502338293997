import { useCallback, useEffect, useMemo, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { Hash, TransactionReceipt } from 'viem';
import { useBlockNumber, useSimulateContract, UseSimulateContractParameters } from 'wagmi';

import { NumberDisplay } from '@layr-labs/eigen-kit/react';
import { IDelegationManagerAbi } from '@layr-labs/eigen-kit/abi';

import useAccount from '@/hooks/useAccount';
import useFetchTokenSharesStrats from '@/hooks/useFetchTokenSharesStrats';
import { useGetAllWithdrawalBuckets } from '@/hooks/useGetAllWithdrawalBuckets';
import { usePEPEPodSummary } from '@/hooks/usePEPEPodSummary';
import { useTokens } from '@/hooks/useTokens';

import { stakeConfig } from '@/config';
import { BIG_NUMBER_MAX, ZERO_ADDRESS } from '@/utils/constants';
import { getCompleteWithdrawalRootsFromTxReceipt } from '@/utils/index';
import { transformWithdrawalToStruct } from '@/utils/uncompletedWithdrawals';

import useWriteContractWithToast from './useWriteContractWithToast';

import type { TransactionLifecycle, UncompletedWithdrawal } from '@layr-labs/eigen-kit/types';
import type { Token } from 'classes/token';

export type CompleteQueuedWithdrawalsParams = UseSimulateContractParameters<
  typeof IDelegationManagerAbi,
  'completeQueuedWithdrawals'
>;

export const prepareCompleteWithdrawalConfig: CompleteQueuedWithdrawalsParams = {
  address: stakeConfig.delegationManagerAddress,
  abi: IDelegationManagerAbi,
  functionName: 'completeQueuedWithdrawals',
};

export interface UseCompleteWithdrawalsProps extends TransactionLifecycle {
  withdrawals: UncompletedWithdrawal[];
  completeRedelegationForAllTokens?: boolean;
  enabled?: boolean;
}

export const useCompleteWithdrawals = ({
  withdrawals,
  completeRedelegationForAllTokens = false,
  enabled,
  onTxnSuccess: onTxSuccess,
  ...args
}: UseCompleteWithdrawalsProps) => {
  const [withdrawalsNeeded, setWithdrawalsNeeded] = useState(false);

  const { address } = useAccount();
  const { refetch: refetchTokenSharesStrats } = useFetchTokenSharesStrats();

  const {
    data: { strategyTokenMap },
  } = useTokens();

  const podSummary = usePEPEPodSummary();
  const { data: buckets, ...withdrawalBuckets } = useGetAllWithdrawalBuckets({
    enabled,
  });
  const [withdrawalRootsCompleted, setWithdrawalRootsCompleted] = useState<Hash[]>([]);

  const withdrawalAmounts = useMemo(() => {
    if (!withdrawals.length || !strategyTokenMap) {
      return {};
    }

    let _withdrawalNeeded = false;
    const withdrawalAmounts: {
      [symbol: string]: { token: Token; shares: bigint; underlying: number };
    } = {};

    for (const withdrawal of withdrawals) {
      withdrawal.strategies.forEach((strategy, i) => {
        const token = strategyTokenMap[strategy.toLowerCase()];

        if (!token) {
          return;
        }

        const shares = BigInt(withdrawal.shares[i] ?? 0);
        const underlying = token?.convertSharesToUnderlying(shares, { format: 'decimal' }) ?? 0;

        withdrawalAmounts[token.symbol] ||= {
          token,
          shares: 0n,
          underlying: 0,
        };
        withdrawalAmounts[token.symbol].shares += shares;
        withdrawalAmounts[token.symbol].underlying += underlying;

        if (shares > 0n) {
          _withdrawalNeeded = true;
        }
      });
    }

    setWithdrawalsNeeded(_withdrawalNeeded);
    return withdrawalAmounts;
  }, [strategyTokenMap, withdrawals]);

  const nextCompletableWithdrawalBlock = Object.keys(withdrawalAmounts).reduce((acc, symbol) => {
    const bucket = symbol.includes('-') ? buckets.batches : buckets.single;
    const withdrawals = bucket?.[symbol] || {
      nextCompletableWithdrawalBlock: BIG_NUMBER_MAX,
    };
    return withdrawals.nextCompletableWithdrawalBlock < acc
      ? withdrawals.nextCompletableWithdrawalBlock
      : acc;
  }, BIG_NUMBER_MAX);

  const blockNumber = useBlockNumber({
    watch: true,
    query: { enabled: !!address && withdrawalsNeeded },
  });

  const withdrawalAssetSymbols = Object.keys(withdrawalAmounts);

  const availableWithdrawalDisplay = (
    <NumberDisplay
      value={withdrawalAmounts[withdrawalAssetSymbols[0]]?.underlying}
      format="tokenAmount"
    />
  );

  const withdrawalArgs = useMemo(
    () =>
      strategyTokenMap
        ? parseWriteContractArgs({
            withdrawals,
            receiveAsTokens: true,
            strategyTokenMap,
          })
        : undefined,
    [withdrawals, strategyTokenMap],
  );

  const { data: completeWithdrawalSimulateData, error: completeWithdrawalSimulateError } =
    useSimulateContract({
      ...prepareCompleteWithdrawalConfig,
      args: withdrawalArgs,
      query: { enabled: !!address && !!withdrawalArgs },
    });

  useEffect(() => {
    if (completeWithdrawalSimulateError?.message) {
      console.error(completeWithdrawalSimulateError.message);
      datadogRum.addError(completeWithdrawalSimulateError.message);
    }
  }, [completeWithdrawalSimulateError?.message]);

  const onTxnSuccess = useCallback(
    async (txReceipt?: TransactionReceipt) => {
      refetchTokenSharesStrats();
      withdrawalBuckets.refetch();
      podSummary.refetch();

      const withdrawalRoots = await getCompleteWithdrawalRootsFromTxReceipt(txReceipt);

      setWithdrawalRootsCompleted((prev) => [...prev, ...withdrawalRoots]);
      onTxSuccess?.(txReceipt);
    },
    [refetchTokenSharesStrats, withdrawalBuckets, podSummary, onTxSuccess],
  );

  // prettier-ignore
  const withdrawalSuffix = withdrawalAssetSymbols.length > 1
    ? `${withdrawalAssetSymbols.length} tokens`
    : <>{availableWithdrawalDisplay} {withdrawalAssetSymbols[0]}</>;

  const {
    data: completeWithdrawalData,
    isPending: isCompleteWithdrawalLoading,
    isError: isCompleteWithdrawalError,
    writeContract: completeWithdrawal,
  } = useWriteContractWithToast({
    loadingMsg: <>Withdrawing {withdrawalSuffix}...</>,
    successMsg: <>Withdrew {withdrawalSuffix}!</>,
    errorMsg: 'Withdrawal submitted.',
    onTxnSuccess,
    ...args,
  });

  const redepositArgs = useMemo(
    () =>
      strategyTokenMap
        ? parseWriteContractArgs({
            withdrawals,
            receiveAsTokens: false,
            strategyTokenMap,
          })
        : undefined,
    [withdrawals, strategyTokenMap],
  );

  const {
    data: completeWithdrawalToEigenLayerSimulatedData,
    error: completeWithdrawalToEigenLayerSimulatedError,
  } = useSimulateContract({
    ...prepareCompleteWithdrawalConfig,
    args: redepositArgs,
    query: { enabled: !!address && !!redepositArgs },
  });

  useEffect(() => {
    if (completeWithdrawalToEigenLayerSimulatedError?.message) {
      console.error(completeWithdrawalToEigenLayerSimulatedError.message);
      datadogRum.addError(completeWithdrawalToEigenLayerSimulatedError.message);
    }
  }, [completeWithdrawalToEigenLayerSimulatedError?.message]);

  // prettier-ignore
  const {
    data: completeWithdrawalToEigenLayerData,
    isPending: isCompleteWithdrawalToEigenLayerLoading,
    isError: completeWithdrawalToEigenLayerError,
    writeContract: completeWithdrawalToEigenLayer,
  } = useWriteContractWithToast({
    loadingMsg: (
      <>
        {completeRedelegationForAllTokens
          ?  `Redelegating unstaked tokens...`
          : <>Redepositing {withdrawalSuffix}</>}
      </>
    ),
    successMsg: (
      <>
        {completeRedelegationForAllTokens
          ?  `Redelegated unstaked tokens!`
          : <>Redeposited {withdrawalSuffix}</>}
      </>
    ),
    errorMsg: "Redeposit submitted.",
    onTxnSuccess,
    ...args
  });

  const handleCompleteWithdrawal = useCallback(() => {
    completeWithdrawalSimulateData?.request &&
      completeWithdrawal(completeWithdrawalSimulateData?.request);
  }, [completeWithdrawalSimulateData?.request, completeWithdrawal]);

  const handleCompleteWithdrawalToEigenLayer = useCallback(() => {
    completeWithdrawalToEigenLayerSimulatedData?.request &&
      completeWithdrawalToEigenLayer(completeWithdrawalToEigenLayerSimulatedData.request);
  }, [completeWithdrawalToEigenLayerSimulatedData?.request, completeWithdrawalToEigenLayer]);

  return {
    completeWithdrawal: handleCompleteWithdrawal,
    completeWithdrawalData: completeWithdrawalData,
    completeWithdrawalError: isCompleteWithdrawalError,
    isCompleteWithdrawalLoading: isCompleteWithdrawalLoading,
    completeWithdrawalToEigenLayer: handleCompleteWithdrawalToEigenLayer,
    completeWithdrawalToEigenLayerData: completeWithdrawalToEigenLayerData,
    completeWithdrawalToEigenLayerError: completeWithdrawalToEigenLayerError,
    isCompleteWithdrawalToEigenLayerLoading: isCompleteWithdrawalToEigenLayerLoading,
    withdrawalRootsCompleted,
    unstakeMenuProps: {
      withdrawalAmounts,
      handleCompleteWithdrawal,
      handleCompleteWithdrawalToEigenLayer,
      nextCompletableWithdrawalBlock,
      currentBlock: blockNumber.data ?? BigInt(0n),
      withdrawalsLoading:
        withdrawalBuckets.isLoading ||
        isCompleteWithdrawalLoading ||
        isCompleteWithdrawalToEigenLayerLoading,
      completeWithdrawal: handleCompleteWithdrawal,
      completeWithdrawalToEigenLayer,
    },
  };
};

// Helpers

export function parseWriteContractArgs({
  withdrawals = [],
  receiveAsTokens,
  strategyTokenMap,
}: {
  withdrawals: UncompletedWithdrawal[];
  receiveAsTokens: boolean;
  strategyTokenMap: Record<string, Token>;
}) {
  return withdrawals.reduce<CompleteQueuedWithdrawalsParams['args']>(
    (acc, withdrawal) => {
      const tokenAddresses = withdrawal.strategies.map(
        (strategy) => strategyTokenMap[strategy.toLowerCase()]?.address ?? ZERO_ADDRESS,
      );
      return [
        [...acc![0], transformWithdrawalToStruct(withdrawal)],
        [...acc![1], tokenAddresses],
        [...acc![2], 0n],
        [...acc![3], receiveAsTokens],
      ] as CompleteQueuedWithdrawalsParams['args'];
    },
    [[], [], [], []],
  );
}
