import { useBlockNumber, UseBlockNumberParameters } from 'wagmi';

import { timeToBlock, TimeToBlockFormat } from '@/utils/display';

interface UseTimeToBlockProps<T extends TimeToBlockFormat = 'relative'> {
  targetBlock?: bigint | number | null;
  startBlock?: bigint | number | null;
  format?: T;
  useBlockNumberParams?: UseBlockNumberParameters;
}

export const useTimeToBlock = <T extends TimeToBlockFormat = 'relative'>({
  targetBlock,
  startBlock,
  format,
  useBlockNumberParams,
}: UseTimeToBlockProps<T>) => {
  const blockNumber = useBlockNumber({
    query: { refetchInterval: 60000 },
    ...useBlockNumberParams,
  });

  const currentBlock = blockNumber.data;

  return {
    ...blockNumber,
    data:
      currentBlock && targetBlock
        ? timeToBlock(startBlock ?? Number(currentBlock), targetBlock, format)
        : null,
    currentBlock,
  };
};
