import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { Address } from 'abitype';

import {
  UncompletedMultiAssetWithdrawalsWithToken,
  UncompletedWithdrawalsBySymbol,
} from '@layr-labs/eigen-kit/types';

import useAccount from '@/hooks/useAccount';

import { api } from '@/utils/api';
import { getAllWithdrawalBucketsForWithdrawer } from 'utils/uncompletedWithdrawals';

type BucketReturnType = {
  single?: UncompletedWithdrawalsBySymbol;
  batches?: {
    [symbols: string]: UncompletedMultiAssetWithdrawalsWithToken;
  };
};

export const useGetAllWithdrawalBuckets = (
  options: Omit<UseQueryOptions<BucketReturnType>, 'queryKey' | 'queryFn'> = {},
) => {
  const { address } = useAccount();
  const { enabled, ...restOptions } = options;

  const { data: blockNumber } = api.subgraph.getBlockHead.useQuery(
    { subgraph: 'eigenlayer-delegation-raw-events' },
    { refetchInterval: 5_000, trpc: { context: { skipBatch: true } } },
  );

  const withdrawals = useQuery({
    queryKey: ['allWithdrawalBucketsForWithdrawer', address, Number(blockNumber ?? 0)],
    queryFn: () => getAllWithdrawalBucketsForWithdrawer(address as Address),
    enabled: enabled !== false && !!address && !!blockNumber,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
    refetchOnReconnect: true,
    ...restOptions,
  });

  useEffect(() => {
    if (withdrawals.error) {
      datadogRum.addError(withdrawals.error);
    }
  }, [withdrawals.error]);

  return {
    ...withdrawals,
    data: withdrawals.data ?? { single: undefined, batches: undefined },
  } as Omit<UseQueryResult<BucketReturnType>, 'data'> & {
    data: BucketReturnType;
  };
};
