import { useMemo } from 'react';

import { getQueryKey } from '@trpc/react-query';
import { Address } from 'abitype';

import { asType } from '@layr-labs/eigen-kit/util';

import { api } from '@/utils/api';

import useAccount from './useAccount';
import { useInvalidateOnSubgraphBlock } from './useOnBlockNumber';

import type { PEPEPodSummary } from '@layr-labs/eigen-kit/types';

const DEFAULT_SUMMARY: PEPEPodSummary = {
  eigenPodAddress: null,
  onBeaconChainUnproven: 0n,
  onBeaconChainRestaked: 0n,
  onBeaconChainNotRestaked: 0n,
  inPodUnproven: 0n,
  inPodRestakedWithdrawable: 0n,
  inPodRestakedNotWithdrawable: 0n,
  restakingPoints: 0n,
  inPodFullWithdrawal: 0n,
  totalRestakedBalance: 0n,
  unrestakedBalance: 0n,
  withdrawableBalance: 0n,
  withdrawableNow: 0n,
  withdrawableAfterCheckpoint: 0n,
  totalUndelegatedWithdrawals: 0n,
  totalOtherWithdrawals: 0n,
  totalRestakableBalance: 0n,
};

export type UsePEPEPodSummaryValue<T = PEPEPodSummary> = {
  summary: T;
};

export type UsePEPEPodSummaryReturnType<T = PEPEPodSummary> = Omit<
  ReturnType<typeof api.native.pepePodSummary.useQuery>,
  'data'
> & {
  data: UsePEPEPodSummaryValue<T>;
};

export type UsePEPEPodSummaryOptions<T extends PEPEPodSummary = PEPEPodSummary> = {
  podOwner?: Address;
  select?: (data?: PEPEPodSummary) => UsePEPEPodSummaryValue<T>;
} & Parameters<typeof api.native.pepePodSummary.useQuery>[1];

export const usePEPEPodSummary = <T extends PEPEPodSummary>({
  podOwner,
  enabled,
  select = (data?: PEPEPodSummary) => ({ summary: data }) as UsePEPEPodSummaryValue<T>,
  ...options
}: UsePEPEPodSummaryOptions<T> = {}): UsePEPEPodSummaryReturnType<T> => {
  const { address: accountAddress } = useAccount();
  const address = (podOwner || accountAddress)?.toLowerCase() ?? '';

  const query = api.native.pepePodSummary.useQuery(
    {
      podOwner: asType<Address>(address),
    },
    {
      select: (data: PEPEPodSummary | undefined) =>
        select(!data?.eigenPodAddress ? DEFAULT_SUMMARY : data),
      enabled: enabled !== false,
      ...options,
    },
  );

  useInvalidateOnSubgraphBlock({
    subgraph: 'eigen-pod-raw-events',
    queryKey: getQueryKey(api.native.pepePodSummary),
  });

  const data = useMemo(
    () => ({ summary: { ...DEFAULT_SUMMARY, ...query.data?.summary } as T }),
    [query.data?.summary],
  );

  return { ...query, data };
};
